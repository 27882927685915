<template>
    <div class="filter__wrap">
        <div class="filter__field mr-5">
            <v-checkbox label="Select all" v-model="selectAllChecked" @click="$emit('selected-all', selectAllChecked)"></v-checkbox>
        </div>
        <div class="filter__field mr-5">
            <v-checkbox label="Select new" v-model="selectNewChecked" @click="$emit('selected-new', selectNewChecked)"></v-checkbox>
        </div>
        <v-btn
            color="primary"
            @click="$emit('on-choose-today')"
            class="mr-auto elevation-1">
            {{ $t('general.today') }}
        </v-btn>
        <v-btn
            color="primary"
            @click="$emit('on-choose-yesterday')"
            class="mr-auto elevation-1">
            {{ $t('general.yesterday') }}
        </v-btn>
        <v-btn
            color="primary"
            @click="$emit('on-choose-weekly')"
            class="mr-auto elevation-1">
            {{ $t('general.weekly') }}
        </v-btn>
        <download-excel
            :data="selected"
            :fields="excelFields"
            class="mr-auto"
            :name="todayDate"
        >
            <v-btn
                color="primary"
                :disabled="selected.length < 1"
                class="elevation-1">
                {{ $t('general.excel-export') }}
            </v-btn>
        </download-excel>
        <v-btn
            color="primary"
            @click="onSelectedMode"
            :disabled="isBlockBtnPrint"
            :loading="loading_print_items"
            class="mr-auto elevation-1">
            {{ $t(`general.${this.selectedItem.toLowerCase()}-labels`) }}
        </v-btn>
        <div class="filter__field">
            <v-select
                label="Actions"
                v-model="selectedItem"
                :items="['Print', 'Delete', 'Mail']"
            ></v-select>
        </div>
        <v-dialog v-model="dialogDeleteSelectedItems" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">{{ $t('general.are-you-sure-you-want-items') }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDeleteSelectedItems"
                    >{{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteSelectedItemsConfirm"
                    >{{ $t('general.ok') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import Order from "../../../models/Order";

import {mapActions} from "vuex";

export default {
    name: "OrderFilter",
    props: ['selected', 'loading_print_items'],
    components: {},
    data: () => ({
        selectAllChecked: false,
        selectNewChecked: false,
        selectedItem: 'Print',
        dialogDeleteSelectedItems: false,
        excelFields: {
            "Name": "name",
            "Date": "date",
            "Address": "delivery.address",
            "Zip": "delivery.zip",
            "Vat number": "delivery.vat_number",
            "Info": "delivery.info",
            "Phone": "phone",
            "Email": "email",
            "Details": "details",
            "Payment method": "payment_method",
            "Time": "time",
            "Status": "status",
            "Amount": "amount",
            "Token": "token",
            "Delivery type": "delivery_type",
            "Created": "created_at",
            "Delivery cost": "delivery_cost",
        },
        todayDate: `${new Date().toString()}.xls`
    }),
    computed: {
        isBlockBtnPrint() {
            return !this.selected.length
        },
    },
    methods: {
        ...mapActions(["destroyOrder"]),
        onSelectedMode() {
          this.selectedItem === "Print" ? this.onPrintItems() : this.selectedItem === "Delete" ? this.onDeleteItems() : this.onMailItems()
        },
        onDeleteItems() {
            this.dialogDeleteSelectedItems = true
        },
        closeDeleteSelectedItems() {
            this.dialogDeleteSelectedItems = false;
        },
        async deleteSelectedItemsConfirm() {
            this.selected.map((item) => {
               this.destroyOrder(item);
            })
            await this.$emit('init-items')
            this.closeDeleteSelectedItems()
        },
        onPrintItems() {
            this.$emit('print-labels', this.selected)
        },
        onMailItems() {
            this.$emit('mail-labels', this.selected)
        },
    },
}
</script>

<style scoped lang="scss">

.filter__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter__field {
        background: #fff;
        padding: 11px 10px;
        border-radius: 5px;
        max-height: 65px;
    }
}


</style>
